import classNames from "classnames";
import { includes, isEmpty } from "lodash";
import { Icons } from "prefab";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { Component } from "react";
import { FormattedNumber } from "react-intl";
import { Table } from "workbench";
import {
  CAMPAIGN_ACTIONS,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  CAMPAIGN_STATUSES,
  CAMPAIGN_TYPE,
  DEFAULT_ACTION_ICON_COLUMN,
  DEFAULT_PAGE_SIZE,
  SCOPES,
  SCOPE_ACTIONS,
  TAG_TYPE,
} from "../../../../constants";
import pageStyles from "../../../../styles/App.module.scss";
import {
  checkScopes,
  createdAtColumn,
  createdByColumn,
  formatDateTime,
  getParams,
  lastUpdatedAtColumn,
  lastUpdatedByColumn,
  modifyTableColumns,
} from "../../../../utils";
import ActionIcon from "../../../common/ActionIcon";
import { ActiveTableCell } from "../../../common/ActiveTableCell";
import BulkActionButtons from "../../../common/BulkActionButtons";
import FilterChips from "../../../common/Filters/FilterChips";
import FooterControls from "../../../common/FooterControls";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import CancelCampaignMedia from "../bulkActions/CancelCampaigns";
import PauseOrResumeActions from "../bulkActions/PauseOrResumeActions";
import MoveCampaignMedia from "../bulkActions/MoveCampaigns";
import {
  getStatus,
  getStatusIconLegend,
  isCampaignStatusChangesAvailable,
  validateCampaignsAction,
} from "../utils";
import { combineDateTimeToISO } from "../../../../utils/formatDateTime";

const { RemoveIcon, PlayIcon, PauseIcon, EditIcon, CancelIcon, SwapIcon } = Icons;
class MediaList extends Component {
  state = {
    selectedColumns: ["Media", "Target Group", "Start Date", "End Date", "Status"],
    selection: [],
    openedBulkAction: null,
    currentCampaign: {},
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  onRowSelect = (selection) =>
    this.setState({
      selection,
    });

  validateMedaiAction = (actionType) => {
    const { selection } = this.state;
    const {
      data: { media },
    } = this.props;
    const selectedMedia = media.data
      ? media.data.filter(
          (media) =>
            selection.includes(media.id) &&
            CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[actionType].includes(media.status)
        )
      : [];
    return selectedMedia.length === selection.length;
  };

  closeBulkAction = () => {
    this.setState({ openedBulkAction: null });
  };

  openBulkAction = (bulkAction) => {
    this.setState({ openedBulkAction: bulkAction });
  };

  handleCampaignAction = (action, props) => {
    this.setState({
      openedBulkAction: action,
      currentCampaign: props,
    });
  };

  //Fnc to handle campaign clear cancel action from individual drop down menu selection
  handleCampaignCancelAction = (props) => {
    const {
      ps,
      page,
      sort,
      match: {
        params: { campaignId },
      },
      filters,
    } = this.props;
    this.props.actions.cancelCampaigns(
      {
        ids: [props.id],
        fromDate: "",
        clearCancellation: true,
      },
      CAMPAIGN_TYPE.MEDIA,
      {
        ...getParams({
          ps,
          page,
          sort,
        }),
        campaignId,
      },
      () => {},
      false,
      filters
    );
  };

  handleCampaignClearAction = (action, props) => {
    const { ps, page, sort, filters } = this.props;
    this.props.actions.pauseOrResumeOneOrMoreMedia(
      [props.id],
      action,
      {
        clearPause: true,
      },
      {
        ...getParams({
          ps,
          page,
          sort,
        }),
        campaignId: props.campaignId,
      },
      filters
    );
  };

  render = () => {
    const {
      history,
      data: { media, isLoading },
      hiddenColumns,
      filters,
      userData,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
      match: {
        params: { campaignId },
      },
      campaigns: {
        campaign: { status: campaignStatus },
      },
    } = this.props;
    const {
      selectedColumns,
      reorderedColumns,
      selection,
      openedBulkAction,
      currentCampaign,
    } = this.state;
    const query = queryString.parse(history.location.search);
    const resultsCount = media.totalCount || 0;
    const selectionInfo = {
      count: selection.length,
      label: selection.length === 1 ? "Media" : "Medias",
    };
    const selectedMedia =
      selection.length > 0 && isEmpty(currentCampaign)
        ? media.data?.filter((row) => selection.includes(row.id))
        : [currentCampaign];
    // const isPaused = validateCampaignsAction(
    //   media,
    //   { parentSelection: selection },
    //   CAMPAIGN_ACTIONS.PAUSE
    // );
    //isPaused is determined bu current bulk action
    const isPaused = openedBulkAction === CAMPAIGN_ACTIONS.PAUSE;
    //  const isEdit = isCampaignStatusChangesAvailable(selectedMedia, openedBulkAction);
    // const isEdit = isCampaignStatusChangesAvailable(selectedTargetGroupData, openedBulkAction);
    const isEdit = isCampaignStatusChangesAvailable(
      selectedMedia,
      isPaused ? CAMPAIGN_STATUSES.PAUSED : CAMPAIGN_STATUSES.ACTIVE
    );
    const mediaHeader =
      isEdit && isPaused
        ? "RightPanelHeader.editPauseMedia"
        : isEdit
        ? "RightPanelHeader.editResumeMedia"
        : isPaused
        ? "RightPanelHeader.pauseMedia"
        : "RightPanelHeader.resumeMedia";

    const columns = checkScopes(
      [
        {
          id: "name",
          Header: "Media",
          accessor: "name",
          width: 200,
        },
        {
          id: "targetGroup",
          Header: "Target Group",
          accessor: (d) =>
            ActiveTableCell(d.targetGroupId, d.targetGroupName, () =>
              this.props.onFilterIdSelect(d.targetGroupId)
            ),
        },
        createdByColumn(this.props.onFilterIdSelect),
        {
          id: "startDate",
          Header: "Start Date",
          showTimeZone: true,
          accessor: (d) =>
            formatDateTime(combineDateTimeToISO(d.validity.fromDate, d.validity.startTime)),
        },
        {
          id: "endDate",
          Header: "End Date",
          showTimeZone: true,
          accessor: (d) =>
            formatDateTime(combineDateTimeToISO(d.validity.toDate, d.validity.endTime)),
        },
        {
          id: "status",
          Header: "Status",
          accessor: (d) => getStatus(d, this.props.onFilterSelect),
          width: 150,
        },
        {
          id: "estimatedReach",
          Header: "Estimated Reach",
          accessor: "estimatedReach",
          Cell: (props) => <FormattedNumber value={props.value} />,
        },
        {
          id: "actualReach",
          Header: "Actual Reach",
          accessor: "actualReach",
          Cell: (props) => <FormattedNumber value={props.value} />,
        },
        {
          id: "id",
          Header: "ID",
          accessor: (d) => ActiveTableCell(d.id, d.code, () => this.props.onFilterIdSelect(d.id)),
          width: 80,
        },
        {
          id: "uuid",
          Header: "UUID",
          accessor: (d) => ActiveTableCell(d.id, d.id, () => this.props.onFilterIdSelect(d.id)),
          width: 100,
          onlyInAdmin: true,
        },
        {
          id: "country",
          Header: "Country",
          accessor: (d) =>
            ActiveTableCell(d.countryId, d.countryName, () =>
              this.props.onFilterIdSelect(d.countryId)
            ),
          width: 100,
        },
        {
          id: "maxPlays",
          Header: "Max Plays",
          accessor: "maxPlays",
          Cell: (props) => <FormattedNumber value={props.value} />,
          width: 80,
        },
        {
          id: "maxPlaysPerScreen",
          Header: "Max Plays per Screen",
          //SLATE-1386 Nov21 Anushree:- maxPlaysPerScreen is not available in api response, we get campaignMaxPlayPerScreen
          //accessor: "maxPlaysPerScreen",
          accessor: "campaignMaxPlayPerScreen",
          Cell: (props) => <FormattedNumber value={props.value} />,
          width: 80,
        },
        {
          id: "pendingPlays",
          Header: "Pending",
          accessor: "pendingPlays",
          Cell: (props) => <FormattedNumber value={props.value} />,
          width: 80,
        },
        {
          id: "playedPlays",
          Header: "Played",
          accessor: "playedPlays",
          Cell: (props) => <FormattedNumber value={props.value} />,
          width: 80,
        },
        {
          id: "failedPlays",
          Header: "Failed",
          accessor: "failedPlays",
          Cell: (props) => <FormattedNumber value={props.value} />,
          width: 80,
        },
        createdAtColumn("Created On"),
        lastUpdatedByColumn(this.props.onFilterIdSelect),
        lastUpdatedAtColumn(),

        {
          ...DEFAULT_ACTION_ICON_COLUMN,
          width: 96,
          Cell: (props) => (
            <ActionIcon
              iconProps={checkScopes([
                {
                  toolTip: "Tooltip.view",
                  url: `/campaigns/media/${props.original.id}`,
                  iconName: "ViewIcon",
                },
                {
                  toolTip: "Tooltip.moreActions",
                  iconName: "MoreVerticalIcon",
                  dropdown: checkScopes(
                    [
                      {
                        text: "Edit",
                        onClick: () =>
                          this.props.history.push(
                            `/campaign-create/${props.original.campaignId}/media/${props.original.id}`
                          ),
                        icon: <EditIcon />,
                        hideMenuItem: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                          CAMPAIGN_ACTIONS.EDIT
                        ].includes(props.status),
                      },
                      // {
                      //   text: "Logs",
                      //   onClick: () => this.props.history.push(`/logs/ref/${props.original.id}`),
                      //   icon: <LogsIcon />,
                      //   scope: SCOPES.LOGS,
                      // },
                      {
                        text: "Pause",
                        onClick: () =>
                          this.handleCampaignAction(CAMPAIGN_ACTIONS.PAUSE, props.original),
                        hideMenuItem: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                          CAMPAIGN_ACTIONS.PAUSE
                        ].includes(props.original.status),
                        icon: <PauseIcon />,
                      },
                      {
                        text: "Clear Pause",
                        onClick: () =>
                          this.handleCampaignClearAction(CAMPAIGN_ACTIONS.PAUSE, props.original),
                        hideMenuItem: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                          CAMPAIGN_ACTIONS.PAUSE
                        ].includes(props.original.status),
                        icon: <RemoveIcon />,
                      },
                      {
                        text: "Resume",
                        onClick: () =>
                          this.setState({
                            openedBulkAction: CAMPAIGN_ACTIONS.RESUME,
                            currentCampaign: props.original,
                          }),
                        hideMenuItem: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                          CAMPAIGN_ACTIONS.RESUME
                        ].includes(props.original.status),
                        icon: <PlayIcon />,
                      },
                      // {
                      //   text: "Clear Resume",
                      //   onClick: () =>
                      //     this.handleCampaignClearAction(CAMPAIGN_ACTIONS.RESUME, props),
                      //   hideMenuItem: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                      //     CAMPAIGN_ACTIONS.RESUME
                      //   ].includes(props.status),
                      //   icon: <RemoveIcon />,
                      // },
                      {
                        text: "Cancel",
                        onClick: () =>
                          this.handleCampaignAction(CAMPAIGN_ACTIONS.CANCEL, props.original),
                        hideMenuItem: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                          CAMPAIGN_ACTIONS.CANCEL
                        ].includes(props.original.status),

                        icon: <CancelIcon />,
                      },
                      {
                        text: "Clear Cancel",
                        onClick: () => this.handleCampaignCancelAction(props.original),
                        hideMenuItem: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                          CAMPAIGN_ACTIONS.CANCEL
                        ].includes(props.original.status),
                        icon: <RemoveIcon />,
                      },
                      {
                        text: "Move",
                        onClick: () =>
                          this.handleCampaignAction(CAMPAIGN_ACTIONS.MOVE, props.original),
                        //hideMove if media!==draft and the campaignStatus is not in the list of allowed status
                        hideMenuItem: !(
                          props.original.status === CAMPAIGN_STATUSES.DRAFT &&
                          CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.MOVE].includes(
                            campaignStatus
                          )
                        ),
                        icon: <SwapIcon />,
                      },
                    ],
                    userData
                  ),
                },
              ])}
            />
          ),
        },
      ],
      userData
    ).filter((column) => !includes(hiddenColumns, column.Header));

    return (
      <div className={classNames("col-12 clearfix", pageStyles.pageTableContainer)}>
        <div className={pageStyles.statusGroup}>
          {getStatusIconLegend(media.data, TAG_TYPE.CAMPAIGN_STATUS, this.props.onFilterSelect)}
        </div>
        <BulkActionButtons
          isOpen={selection.length > 0}
          selection={[selectionInfo]}
          buttons={[
            {
              text: "Button.pauseMedia",
              onClick: () => {
                this.openBulkAction(CAMPAIGN_ACTIONS.PAUSE);
              },
              icon: "PauseIcon",
              isHidden: !validateCampaignsAction(
                media,
                { parentSelection: selection },
                CAMPAIGN_ACTIONS.PAUSE
              ),
              checkScope: true,
              scope: SCOPES.CAMPAIGNS,
              scopeAction: SCOPE_ACTIONS.WRITE,
              userData,
            },
            {
              text: "Button.resumeMedia",
              onClick: () => {
                this.openBulkAction(CAMPAIGN_ACTIONS.RESUME);
              },
              icon: "PlayIcon",
              isHidden: !validateCampaignsAction(
                media,
                { parentSelection: selection },
                CAMPAIGN_ACTIONS.RESUME
              ),
              checkScope: true,
              scope: SCOPES.CAMPAIGNS,
              scopeAction: SCOPE_ACTIONS.WRITE,
              userData,
            },
            {
              text: "Button.cancelMedia",
              onClick: () => {
                this.openBulkAction(CAMPAIGN_ACTIONS.CANCEL);
              },
              icon: "CancelIcon",
              isHidden: !this.validateMedaiAction(CAMPAIGN_ACTIONS.CANCEL),
              checkScope: true,
              scope: SCOPES.CAMPAIGNS,
              scopeAction: SCOPE_ACTIONS.CANCEL,
              userData,
            },
          ]}
          onClose={() => {
            this.onRowSelect([]);
          }}
        />
        <TableControls
          searchBar
          columnFilter
          showRowSize
          pagination
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          data={media}
          query={query}
          ps={ps}
          page={page}
          tagTypes={[TAG_TYPE.TARGET_GROUP, TAG_TYPE.CAMPAIGN_STATUS, TAG_TYPE.MEDIA]}
          onSearchFilterSelect={this.props.onFilterSelect}
          onFilterChange={this.props.onFilterChange}
          onColumnFilterSelect={this.onColumnSelect}
          onRowSizeChange={onQueryChange}
          filters={filters}
          currentFilters={[this.props.campaigns.campaign.id]}
        />
        <FilterChips
          selected={filters}
          showResultsCount
          resultsCount={resultsCount}
          onFilterChange={this.props.onFilterChange}
        />

        <Table
          data={media.data}
          loading={isLoading || isFilterLoading}
          columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          sorted={sort}
          onSortedChange={onSortedChange}
          // selectable={true}
          selection={selection}
          onSelect={this.onRowSelect}
          LoadingComponent={
            <TableLoading
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            />
          }
          onRowClick={({ original }) => history.push(`/campaigns/media/${original.id}`)}
        />
        <FooterControls
          pagination
          data={media}
          query={query}
          ps={ps}
          page={page}
          onRowSizeChange={onQueryChange}
        />
        {(openedBulkAction === CAMPAIGN_ACTIONS.RESUME ||
          openedBulkAction === CAMPAIGN_ACTIONS.PAUSE) && (
          <PauseOrResumeActions
            header={mediaHeader}
            selectionInfo={selection.length > 0 && isEmpty(currentCampaign) ? selectionInfo : null}
            history={history}
            selected={selectedMedia}
            isPaused={isPaused}
            isEdit={isEdit}
            //showEdit={validateSelectedCampaignsDurations(selectedMedia)}
            showEdit={true}
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            onPauseOrResumeAction={(period) => {
              this.props.actions.pauseOrResumeOneOrMoreMedia(
                selectedMedia.map((media) => media.id),
                openedBulkAction,
                period,
                {
                  ...getParams({
                    ps,
                    page,
                    sort,
                  }),
                  campaignId,
                },
                filters
              );
            }}
          />
        )}
        {openedBulkAction === CAMPAIGN_ACTIONS.CANCEL && (
          <CancelCampaignMedia
            selectionInfo={selection.length > 0 && isEmpty(currentCampaign) ? selectionInfo : null}
            selected={selectedMedia}
            header="RightPanelHeader.cancelMedia"
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            cancelCampaigns={(data) => {
              this.props.actions.cancelCampaigns(
                {
                  ...data,
                  ...{
                    ids: selectedMedia.map((media) => media.id),
                  },
                },
                CAMPAIGN_TYPE.MEDIA,
                {
                  ...getParams({
                    ps,
                    page,
                    sort,
                  }),
                  campaignId,
                },
                this.closeBulkAction,
                false,
                filters
              );
            }}
          />
        )}

        {openedBulkAction === CAMPAIGN_ACTIONS.MOVE && (
          <MoveCampaignMedia
            selected={selectedMedia}
            //parentValidity = target group Validity
            //!parentValidity={selectedMedia[0]?.targetGroup?.validity}
            parentValidity={{
              fromDate: selectedMedia[0].targetGroup?.validity?.fromDate,
              toDate: selectedMedia[0].targetGroup?.validity?.toDate,
            }}
            header="RightPanelHeader.moveMedia"
            item={CAMPAIGN_TYPE.MEDIA}
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            moveCampaigns={(data) => {
              this.props.actions.moveCampaigns(
                { id: selectedMedia[0].id, ...data },
                CAMPAIGN_TYPE.MEDIA,
                {
                  ...getParams({
                    ps,
                    page,
                    sort,
                  }),
                  campaignId,
                },
                this.closeBulkAction,
                false,
                filters
              );
            }}
          />
        )}
      </div>
    );
  };
}

MediaList.propTypes = {
  data: PropTypes.shape({
    media: PropTypes.object,
    isLoading: PropTypes.bool,
  }),
  history: PropTypes.object,
  filters: PropTypes.array,
  userData: PropTypes.object,
  sort: PropTypes.array,
  ps: PropTypes.number,
  page: PropTypes.number,
  onSortedChange: PropTypes.func,
  onQueryChange: PropTypes.func,
  onFilterSelect: PropTypes.func,
  onFilterChange: PropTypes.func,
  onFilterIdSelect: PropTypes.func,
};

export default MediaList;
